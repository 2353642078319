<template>
  <div>
    <v-toolbar class="mb-8 mt-4 elevation-1" flat>
      <v-toolbar-title>
        <slot>{{ title }}</slot>
      </v-toolbar-title>
      <v-spacer />
      <div class="text-center d-print-none">
        <slot name="menu">
          <v-menu v-if="menuItems && menuItems.length > 0" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" v-bind="attrs" v-on="on">{{
                menuTitle
              }}</v-btn>
            </template>
            <v-card class="mx-auto" max-width="400" width="200" tile>
              <v-list dense>
                <v-list-item-group v-model="item" color="primary">
                  <v-list-item
                    :disabled="item.disabled ? true : false"
                    :to="item.to"
                    @click="clickedMenuItem($event, item)"
                    v-for="(item, i) in menuItems"
                    :key="i"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </slot>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Set a Title"
    },
    menuTitle: {
      type: String,
      required: false,
      default: "Menu"
    },
    menuItems: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      item: null
    };
  },
  methods: {
    clickedMenuItem(nativeEvent, menuItem) {
      this.$emit("menuClick", nativeEvent, menuItem);
    }
  }
};
</script>
<style></style>
